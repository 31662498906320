import { FormInstance } from 'antd/es';
import { useCallback } from 'react';
import { NONPOFiledIds } from 'types';
import { useNonPoInvoiceConversionRateApi } from 'api';
import { useTranslate } from 'translations';

export interface ICalcAmountWithOrWithoutWHT {
  formListIndex: number;
  expenseTypeKey: string;
  SelectedAmount: number;
  withholdingTaxAmount: number;
  isIncludeWithHoldingTax?: boolean;
  currencyId: number;
}

interface IUseExpenseCalcAmountWithOrWithoutWHTProps {
  form: FormInstance;
  calcAmountWithOrWithoutWHTObj: ICalcAmountWithOrWithoutWHT;
  currencyId?: number;
  isExpense: boolean;
}

export const useExpenseCalcAmountWithOrWithoutWHT = ({
  form,
  calcAmountWithOrWithoutWHTObj,
  isExpense,
}: IUseExpenseCalcAmountWithOrWithoutWHTProps) => {
  const { t } = useTranslate();
  const mutationConversionRate = useNonPoInvoiceConversionRateApi();

  /**
   * Calculate Amount SAR rate based on
   * New Amount : Including WHT or Excluding  WHT
   */
  const calculateConversionRate = useCallback(
    (newAmount: number, formListIndex: number, currencyId: number) => {
      if (currencyId === undefined || !newAmount) {
        return;
      }
      const payOutSideSaudiYes =
        form.getFieldValue([NONPOFiledIds.RequestDetail_PaymentToBeDoneOutsideSaudiArabia]) ===
        'YES';
      const serviceOutSaudiNo =
        form.getFieldValue([NONPOFiledIds.RequestDetail_ServiceProvidedOutsideSaudiArabia]) ===
        'NO';

      if (!(payOutSideSaudiYes && serviceOutSaudiNo)) {
        return;
      }
      mutationConversionRate
        .mutateAsync({
          currencyId: currencyId,
          amount: newAmount,
        })
        .then((data) => {
          form.setFields([
            {
              name: isExpense
                ? [
                    NONPOFiledIds.BillDetails_BillDetails,
                    formListIndex,
                    NONPOFiledIds.BillDetails_ConversionRate,
                  ]
                : [
                    NONPOFiledIds.QuotationDetail_QuotationDetails,
                    formListIndex,
                    NONPOFiledIds.QuotationDetail_ConversionRate,
                  ],
              value: data.conversionRate,
              errors: [],
            },
          ]);
          form.setFields([
            {
              name: isExpense
                ? [
                    NONPOFiledIds.BillDetails_BillDetails,
                    formListIndex,
                    NONPOFiledIds.BillDetails_InvoiceAmountInSAR,
                  ]
                : [
                    NONPOFiledIds.QuotationDetail_QuotationDetails,
                    formListIndex,
                    NONPOFiledIds.QuotationDetail_QuotedPriceSAR,
                  ],
              value: data.amountWithConversionRate,
              errors: [],
            },
          ]);
        })
        .catch(() => {
          form.setFields([
            {
              name: [
                isExpense
                  ? NONPOFiledIds.BillDetails_BillDetails
                  : NONPOFiledIds.QuotationDetail_QuotationDetails,
                formListIndex,
                isExpense
                  ? NONPOFiledIds.BillDetails_ConversionRate
                  : NONPOFiledIds.QuotationDetail_ConversionRate,
              ],
              value: undefined,
              errors: [t('messages.error.conversationRate')],
            },
          ]);
        });
    },
    [form, isExpense, mutationConversionRate, t]
  );

  /**
   * Calculate and Update
   *
   * Amount without WithHoldingTax
   * Amount including WithHoldingTax
   *
   * Total amount SAR
   */
  const updateFieldBaseOnCalculation = useCallback(
    ({
      formListIndex,
      SelectedAmount,
      withholdingTaxAmount,
      isIncludeWithHoldingTax,
      currencyId,
    }: ICalcAmountWithOrWithoutWHT) => {
      if (!SelectedAmount || withholdingTaxAmount === undefined) {
        return;
      }
      if (isIncludeWithHoldingTax) {
        const amountExcludingWTH = SelectedAmount - withholdingTaxAmount;
        const correctedAmountExcludingWTH = Number.isInteger(amountExcludingWTH)
          ? amountExcludingWTH
          : amountExcludingWTH.toFixed(3);
        if (currencyId !== undefined) {
          calculateConversionRate(correctedAmountExcludingWTH as number, formListIndex, currencyId);
        }

        form.setFields([
          {
            name: isExpense
              ? [
                  NONPOFiledIds.BillDetails_BillDetails,
                  formListIndex,
                  NONPOFiledIds.BillDetails_AmountExcludingWithHoldingTax,
                ]
              : [
                  NONPOFiledIds.QuotationDetail_QuotationDetails,
                  formListIndex,
                  NONPOFiledIds.QuotationDetail_AmountExcludingWithHoldingTax,
                ],
            value: correctedAmountExcludingWTH,
            errors: [],
          },
        ]);
      } else {
        const amountIncludingWTH =
          (Number(SelectedAmount) ?? 0) + (Number(withholdingTaxAmount) ?? 0);
        const correctedAmountIncludingWTH = Number.isInteger(amountIncludingWTH)
          ? amountIncludingWTH
          : amountIncludingWTH.toFixed(3);
        if (currencyId !== undefined) {
          calculateConversionRate(correctedAmountIncludingWTH as number, formListIndex, currencyId);
        }

        form.setFields([
          {
            name: isExpense
              ? [
                  NONPOFiledIds.BillDetails_BillDetails,
                  formListIndex,
                  NONPOFiledIds.BillDetails_AmountIncludingWithHoldingTax,
                ]
              : [
                  NONPOFiledIds.QuotationDetail_QuotationDetails,
                  formListIndex,
                  NONPOFiledIds.QuotationDetail_AmountIncludingWithHoldingTax,
                ],
            value: correctedAmountIncludingWTH,
            errors: [],
          },
        ]);
      }
    },
    [calculateConversionRate, form, isExpense]
  );

  const calculateWithHoldingTaxForAllForAll = useCallback(() => {
    const withHoldingTaxPercentage = form.getFieldValue([
      NONPOFiledIds.RequestDetail_WithHoldingTaxPercentage,
    ]);
    if (withHoldingTaxPercentage === undefined) {
      return;
    }

    if (isExpense) {
      const currency = form.getFieldValue([NONPOFiledIds.RequestDetail_Currency]);

      const billingDetails = form.getFieldValue([NONPOFiledIds.BillDetails_BillDetails]);
      if (billingDetails?.length <= 0) {
        return;
      }

      billingDetails.forEach(
        (billDetail: Record<string, string | number | boolean>, billItemIndex: number) => {
          const billTotalInvoiceAmount = billDetail[NONPOFiledIds.BillDetails_InvoiceAmount];
          if (!billTotalInvoiceAmount) {
            return;
          }

          const billWithHoldingTaxAmount =
            (Number(withHoldingTaxPercentage) * Number(billTotalInvoiceAmount)) / 100;
          const correctedBillWithHoldingTaxAmount = Number.isInteger(billWithHoldingTaxAmount)
            ? billWithHoldingTaxAmount
            : billWithHoldingTaxAmount.toFixed(3);

          const billIncludeWihHoldingTax =
            billDetail[NONPOFiledIds.BillDetails_IncludesWithHoldingTax] === 'YES';

          updateFieldBaseOnCalculation({
            SelectedAmount: Number(billTotalInvoiceAmount),
            currencyId: currency,
            expenseTypeKey: '',
            formListIndex: billItemIndex,
            isIncludeWithHoldingTax: billIncludeWihHoldingTax,
            withholdingTaxAmount: correctedBillWithHoldingTaxAmount as number,
          });
          form.setFields([
            {
              name: [
                NONPOFiledIds.BillDetails_BillDetails,
                billItemIndex,
                NONPOFiledIds.BillDetails_WithHoldingTaxAmount,
              ],
              value: correctedBillWithHoldingTaxAmount,
            },
          ]);
        }
      );
    } else {
      /**
       * A D V A N C E
       * calculate and set with holding tax for quotation
       */
      const quotationDetails = form.getFieldValue([NONPOFiledIds.QuotationDetail_QuotationDetails]);
      if (quotationDetails?.length <= 0) {
        return;
      }

      quotationDetails.forEach(
        (
          quotationDetail: Record<string, string | number | boolean>,
          quotationDetailIndex: number
        ) => {
          const quotationTotalQuotedPrice =
            quotationDetail[NONPOFiledIds.QuotationDetail_QuotedPrice];
          if (!quotationTotalQuotedPrice) {
            return;
          }

          const quotationCurrency = quotationDetail[NONPOFiledIds.QuotationDetail_Currency];

          const quotationWithHoldingTaxAmount =
            (Number(withHoldingTaxPercentage) * Number(quotationTotalQuotedPrice)) / 100;

          const isQuotationIncludesWHT =
            quotationDetail[NONPOFiledIds.QuotationDetail_IncludesWithHoldingTax] === 'YES';

          updateFieldBaseOnCalculation({
            SelectedAmount: Number(quotationTotalQuotedPrice),
            currencyId: Number(quotationCurrency),
            expenseTypeKey: '',
            formListIndex: quotationDetailIndex,
            isIncludeWithHoldingTax: isQuotationIncludesWHT,
            withholdingTaxAmount: quotationWithHoldingTaxAmount,
          });

          form.setFields([
            {
              name: [
                NONPOFiledIds.QuotationDetail_QuotationDetails,
                quotationDetailIndex,
                NONPOFiledIds.QuotationDetail_WithHoldingTaxAmount,
              ],
              value: quotationWithHoldingTaxAmount,
            },
          ]);
        }
      );
    }
  }, [form, isExpense, updateFieldBaseOnCalculation]);

  // useEffect(() => {
  //   if (calcAmountWithOrWithoutWHTObj) {
  //     updateFieldBaseOnCalculation(calcAmountWithOrWithoutWHTObj);
  //   }
  // }, [calcAmountWithOrWithoutWHTObj, updateFieldBaseOnCalculation]);

  return { calculateWithHoldingTaxForAllForAll };
};
