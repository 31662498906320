import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Input, notification, Space, Spin } from 'antd';
import { useTranslate } from 'translations';
import {
  useDispatch,
  getGroupMembersStart,
  GroupMembersData,
  useSelector,
  getGroupMembersByLastNameStart,
  GroupMembersByLastNameData,
} from 'store';
import { RequestStatus, GroupMember } from 'types';
import { TextStyled } from 'ui';
import { LoadingOutlined } from '@ant-design/icons';
import { MSTeamsUserAvatar } from 'routes/MSTeams/MSTeamsUserAvatar';
import { useTasksContext } from 'routes';
import { FilterOption } from '../../../../Filter/Filter.types';
import { StyledAutoComplete, StyledSearchIcon } from './SearchUser.styled';

type Options = Option[];

interface Option {
  value: string;
  id: string | null;
}

interface SearchUserProps {
  groupId?: string;
  currentSearchUserId: (userID: string, userName: string) => void;
  groupTasksOptions?: FilterOption[];
  suffix?: boolean;
  assigneeName?: string;
  inputStyle?: React.CSSProperties;
  rootPath?: string;
}

export const SearchUser: FC<SearchUserProps> = ({
  currentSearchUserId,
  groupId,
  groupTasksOptions,
  suffix,
  assigneeName,
  inputStyle,
  rootPath,
}) => {
  const { t } = useTranslate();
  const groupsMembers = useSelector(GroupMembersData);
  const groupsMembersBylastName = useSelector(GroupMembersByLastNameData);
  const [value, setValue] = useState('');
  const [data, setData] = useState<GroupMember[]>();
  const [options, setOptions] = useState<Options>([]);
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedUserId, setSelectedUserId] = useState('');
  const timeoutId = useRef<ReturnType<typeof setTimeout>>();
  const inputRef = useRef<Input>(null);
  const { rootPath: contextRootPath } = useTasksContext();

  const searchUsers = useCallback(
    (searchText: string) => {
      if (timeoutId.current && groupId) {
        clearTimeout(timeoutId.current);
      }

      const capitalStr = searchText.replace(/^\w/, (c) => c.toUpperCase());
      timeoutId.current = setTimeout(() => {
        dispatch(
          getGroupMembersStart({
            params: {
              memberOfGroup: groupId,
              firstNameLike: `%${capitalStr}%`,
            },
            rootPath: rootPath ?? contextRootPath,
          })
        );
        dispatch(
          getGroupMembersByLastNameStart({
            params: {
              memberOfGroup: groupId,
              lastNameLike: `%${capitalStr}%`,
            },
            rootPath,
          })
        );
        inputRef?.current?.focus();
      }, 350);
    },
    [dispatch, groupId, rootPath, contextRootPath]
  );

  useEffect(() => {
    if (groupTasksOptions && groupsMembers.data && groupsMembersBylastName.data) {
      const allData = [...groupsMembers.data, ...groupsMembersBylastName.data];
      const fiterData = allData.filter((item, index) => {
        return allData.indexOf(item) === index;
      });
      const groupTasksFilterData = fiterData.filter((item) =>
        groupTasksOptions.some((option) => option.value === item.id)
      );
      setData(groupTasksFilterData);
    } else if (groupsMembers.data && groupsMembersBylastName.data) {
      const allData = [...groupsMembers.data, ...groupsMembersBylastName.data];
      const fiterData = allData.filter((item, index) => {
        return allData.indexOf(item) === index;
      });
      setData(fiterData);
    }
  }, [groupsMembers.data, groupsMembersBylastName.data, groupTasksOptions]);

  const onSearch = useCallback(
    (searchText: string) => {
      if (searchText) {
        searchUsers(searchText);
      }
      inputRef?.current?.focus();
    },
    [searchUsers]
  );

  const onSelect = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: string, option: any) => {
      setSelectedUserId(option.id);
      currentSearchUserId(option.id, option.value);
      setOptions([]);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSelectedUserId]
  );

  const onChange = useCallback(
    (data: string) => {
      setValue(data);
      inputRef?.current?.focus();

      if (!data) {
        setOptions([]);
        setSelectedUserId('');
      }
    },
    [setSelectedUserId]
  );

  useEffect(() => {
    if (data) {
      const options = data.map((user, index) => ({
        key: index,
        label: (
          <Space size="small">
            <MSTeamsUserAvatar id={user.id} name={[user.firstName, user.lastName].join(' ')} />
            <Space direction="horizontal">
              <TextStyled>{[user.firstName, user.lastName].join(' ') ?? ''}</TextStyled>
              <TextStyled>{user.email ?? ''}</TextStyled>
            </Space>
          </Space>
        ),
        value: [user.firstName, user.lastName].join(' ') ?? '',
        id: user.id,
        email: user.email,
      }));

      setOptions(options);
      inputRef?.current?.focus();
    }

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [data]);

  useEffect(() => {
    if (
      groupsMembers.status === RequestStatus.rejected &&
      groupsMembersBylastName.status === RequestStatus.rejected
    ) {
      notification.error({
        message: t('messages.error.wentWrong'),
        description: t('messages.error.tryAgainLater'),
      });
    }
  }, [groupsMembers.status, groupsMembersBylastName.status, t]);

  useEffect(() => {
    if (!value) {
      setOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!value && groupTasksOptions) {
      setValue(assigneeName as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchUI = useCallback(
    () =>
      groupsMembers.status === RequestStatus.pending &&
      groupsMembersBylastName.status === RequestStatus.pending &&
      value?.length ? (
        <Spin size="small" indicator={<LoadingOutlined spin />} />
      ) : (
        <StyledSearchIcon />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [suffix]
  );

  return (
    <StyledAutoComplete
      options={options}
      onSelect={onSelect}
      onSearch={onSearch}
      onChange={onChange}
      value={value}
      {...(groupTasksOptions
        ? { notFoundContent: false }
        : { notFoundContent: t('msteams.notFoundContent') })}
      {...(groupTasksOptions ? { allowClear: false } : { allowClear: true })}
      {...(groupTasksOptions ? { autoFocus: false } : { autoFocus: true })}
    >
      <Input
        style={inputStyle}
        ref={inputRef}
        {...(suffix ? { suffix: searchUI() } : { prefix: searchUI() })}
        size="middle"
        placeholder={groupTasksOptions ? t('task.assignedTo') : t('msteams.search')}
      />
    </StyledAutoComplete>
  );
};
