import { Rule } from 'antd/lib/form';
import moment from 'moment';
import { TType } from 'translations';

export const NUMBER_PATTERN = new RegExp(/^[+]?[0-9]*$/);
export const ARABIC_PATTERN = new RegExp(/^[\u0600-\u06FF\u0750-\u077F\s]*$/);
export const ENGLISH_PATTERN = new RegExp(/^[a-zA-Z\s]*$/);
export const ENGLISH_PATTERN_ALPHA_NUMERIC = new RegExp(/^[a-zA-Z0-9\s!@#$%^&*()-_+=,.?]+$/);

export const VALIDATE_MESSAGES = (t: TType) => ({
  required: t('requestForm.validation.rule.message.isRequired'),

  string: {
    max: t('requestForm.validation.rule.message.maxLength'),
    min: t('requestForm.validation.rule.message.minimumLength'),
  },
  array: {
    max: t('requestForm.validation.rule.message.maxArrayLength'),
    range: t('requestForm.validation.rule.message.rangeArrayLength'),
  },
  number: {
    min: t('requestForm.validation.rule.message.minNumber'),
    max: t('requestForm.validation.rule.message.maxNumber'),
  },
  types: {
    number: t('requestForm.validation.rule.message.types.number'),
  },
});

export const INPUT_VALIDATION_RULES = (
  isRequired: boolean,
  maxLength?: number,
  minLength?: number
): Rule[] => [
  {
    required: isRequired,
    max: maxLength,
    min: minLength,
  },
];

export const INPUT_VALIDATION_MAX_DIGIT_PATTERN = (
  isRequired: boolean,
  maxDigit: number,
  errorMessage: string,
  minDigit = 1
): Rule[] => {
  const digitRegExp = new RegExp(`^-?[0-9]{${minDigit},${maxDigit}}$`, 'g');
  return [
    {
      required: isRequired,
      type: 'number',
    },
    {
      pattern: digitRegExp,
      message: errorMessage,
    },
  ];
};
export const INPUT_VALIDATION_MAX_DIGIT_PATTERN_INPUT_STRING = (
  isRequired: boolean,
  maxDigit: number,
  errorMessageNonNumeric: string,
  errorMessageMaxDigit: string,
  minDigit = 1
): Rule[] => {
  const nonNumericRegExp = new RegExp(`^-?0*[0-9]*$`, 'g'); // Allows numeric values with leading zeros
  const maxDigitRegExp = new RegExp(`^-?\\d{${minDigit},${maxDigit}}$`); // Allows only within the min and max digit limits

  return [
    {
      required: isRequired,
      type: 'string',
      transform: (value: string) => value.trim(), // Trim spaces before validation
    },
    {
      pattern: nonNumericRegExp,
      message: errorMessageNonNumeric, // Error for non-numeric values
    },
    {
      pattern: maxDigitRegExp,
      message: errorMessageMaxDigit, // Error for exceeding max digit limit
    },
  ];
};

export const INPUT_VALIDATION_MAX_CHARACTERS_PATTERN = (
  isRequired: boolean,
  maxDigit: number,
  errorMessage: string,
  minDigit = 1
): Rule[] => {
  const digitRegExp = new RegExp(`^.{${minDigit},${maxDigit}}$`, 'g');
  return [
    {
      required: isRequired,
      type: 'string',
    },
    {
      pattern: digitRegExp,
      message: errorMessage,
    },
  ];
};

export const INPUT_VALIDATION_DECIMAL_Digit_PATTERN = (
  isRequired: boolean,
  uponDecimal: number,
  errorMessage: string,
  startDecimal = 1
): Rule[] => {
  const digitRegExp = new RegExp(`^\\d+(\\.\\d{${startDecimal},${uponDecimal}})?$`, 'g');

  return [
    {
      required: isRequired,
    },
    {
      pattern: digitRegExp,
      message: errorMessage,
    },
  ];
};

export const INPUT_VALIDATION_FOR_100_PERCENTAGE = (
  isRequired: boolean,
  errorMessage: string
): Rule[] => {
  const digitRegExp = new RegExp(`^(100(\\.0{1,2})?|[0-9]{0,2}(\\.[0-9]{1,2})?)$`, 'g');
  return [
    {
      required: isRequired,
      type: 'number',
    },
    {
      pattern: digitRegExp,
      message: errorMessage,
    },
  ];
};

export const VALIDATION_RULE_FALSE = [
  {
    required: false,
  },
];

export const FORM_ITEM_REQUIRED_RULE = [
  {
    required: true,
  },
];

export const FORM_ITEM_REQUIRED_TRUE_RULE: Rule[] = [
  ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value) {
        return Promise.reject(new Error(`${(_ as { field: string }).field} is required`));
      }
      return Promise.resolve();
    },
  }),
];

export const FORM_ITEM_NUMBER_RULE: Rule[] = [
  {
    required: true,
    type: 'number',
  },
];

export const FORM_ITEM_NOT_REQUIRED_NUMBER_RULE: Rule[] = [
  {
    type: 'number',
  },
];

export const FORM_ITEM_FILE_RULES: Rule[] = [
  {
    required: true,
  },
  {
    max: 3,
    type: 'array',
  },
];

export const FORM_ITEM_FILE_RULES_MAX: Rule[] = [
  {
    max: 3,
    type: 'array',
  },
];

export const FORM_ITEM_50_CHARACTER_RULES: Rule[] = [
  {
    max: 50,
    type: 'string',
  },
];

export const FORM_ITEM_10_CHARACTER_RULES: Rule[] = [
  {
    max: 10,
    type: 'string',
  },
];

export const FORM_ITEM_MIN_10_CHARACTER_RULES: Rule[] = [
  {
    min: 10,
    type: 'string',
  },
];

export const FORM_ITEM_20_CHARACTER_RULES: Rule[] = [
  {
    max: 20,
    type: 'string',
  },
];

export const FORM_ITEM_30_CHARACTER_RULES: Rule[] = [
  {
    max: 30,
    type: 'string',
  },
];

export const FORM_ITEM_MOBILE_NUMBER_WITHOUT_COUNTRY_CODE_RULES: Rule[] = [
  {
    max: 12,
    min: 9,
    type: 'string',
    message: 'Mobile Number must be between 9 and 12 digits',
  },
];

export const FORM_FILE_RULES_NOT_REQUIRED: Rule[] = [
  {
    max: 3,
    type: 'array',
  },
];

export const FORM_MAX_DIGITS_8_REQUIRED_NUMBER: Rule[] = [
  {
    required: true,
  },
  {
    max: 8,
    type: 'number',
  },
];

export const ENGLISH_FIELDS_ALPHA_NUMERIC_RULES = (required: boolean, t: TType): Rule[] => {
  return [{ required: required }, englishPatternAlphaNumericRule(t)];
};

export const FORM_MAX_DIGITS_8_NUMBER: Rule[] = [{ max: 8, type: 'number' }];
export const FORM_MAX_DIGITS_10_NUMBER: Rule[] = [{ max: 10, type: 'number' }];

export function numberPatternRule(t: TType) {
  return {
    pattern: NUMBER_PATTERN,
    message: t('validation.form.number'),
  };
}

export function arabicPatternRule(t: TType) {
  return {
    pattern: ARABIC_PATTERN,
    message: t('validation.form.arabicLetters'),
  };
}

export function englishPatternRule(t: TType) {
  return {
    pattern: ENGLISH_PATTERN,
    message: t('validation.form.englishLetters'),
  };
}

export const disableReturningDate = (current: moment.Moment): boolean => {
  const toDay = moment().toDate();
  const newDate = moment(toDay.setDate(toDay.getDate() - 1));
  return current.isBefore(newDate);
};

export const disableFutureDate = (currentDate: moment.Moment) => {
  return currentDate.isAfter();
};

export function englishPatternAlphaNumericRule(t: TType) {
  return {
    pattern: ENGLISH_PATTERN_ALPHA_NUMERIC,
    message: t('validation.form.englishLetters'),
  };
}
