import React, { FC, useCallback, useContext } from 'react';
import { FormInstance } from 'antd/lib/form';
import { TType } from 'translations';
import { UploadFile } from 'antd/lib/upload/interface';
import { CreateFormItemType, NONPOFiledIds, SelectOptions } from 'types';
import { createFormField, getSelectOptionObFromNonPoLookupItem } from 'utils/formCreation';
import {
  INPUT_VALIDATION_DECIMAL_Digit_PATTERN,
  INPUT_VALIDATION_MAX_CHARACTERS_PATTERN,
} from 'utils';
import moment from 'moment/moment';
import { NonPoRequestFormContext } from '../NonPoRequestForm';
import { CustomFormGroupWrapper } from './CustomFormGroupWraper';

type Props = {
  t: TType;
  hasInitialValues: boolean;
  form?: FormInstance;
};

export const BillDetailsFormGroup: FC<Props> = ({ t, hasInitialValues, form }) => {
  const {
    isVatVisible,
    isWthVisible,
    isEdit,
    dataLookUpResponse,
    isConversationRateSuccess,
    isConversationRateFailed,
    isLoadingConversationRate,
    getServiceOutsideSAValue,
    getPaymentOutsideSAValue,
    updateDeletedQuotationIds,
  } = useContext(NonPoRequestFormContext);

  const disableFutureDate = useCallback((current: moment.Moment) => {
    const today = moment().toDate();
    return current.isAfter(today);
  }, []);

  const getLookUps = useCallback(
    (fieldId: NONPOFiledIds) => {
      let selectOptionsOfNonPoLookUps: SelectOptions = [];
      if (dataLookUpResponse) {
        switch (fieldId) {
          case NONPOFiledIds.BillDetails_IncludesWithHoldingTax:
            selectOptionsOfNonPoLookUps = getSelectOptionObFromNonPoLookupItem(
              dataLookUpResponse?.lookups?.INVOICE_AMOUNT_INCLUDE_WITHOLDING_TAX
            );
            break;
          case NONPOFiledIds.BillDetails_VatPercentage:
            selectOptionsOfNonPoLookUps = getSelectOptionObFromNonPoLookupItem(
              dataLookUpResponse?.lookups?.VAT_PERCENTAGE
            );
            break;
        }
      }
      return selectOptionsOfNonPoLookUps;
    },
    [dataLookUpResponse]
  );

  const onAttachmentChange = useCallback(
    (formListIndex: number, file?: UploadFile | UploadFile[]) => {
      if (form) {
        form.setFields([
          {
            name: [
              NONPOFiledIds.BillDetails_BillDetails,
              formListIndex,
              NONPOFiledIds.BillDetails_AttachmentList,
            ],
            value: file,
          },
        ]);
      }
    },
    [form]
  );

  const BillDetailsFormGroupFields = useCallback<(formListName: number) => CreateFormItemType[]>(
    (formListName) => {
      const getlabelForInvoiceAmount = () => {
        if (
          (getPaymentOutsideSAValue && getServiceOutsideSAValue) ||
          (getPaymentOutsideSAValue && !getServiceOutsideSAValue)
        ) {
          return t('billDetails.invoiceAmount.label');
        }

        return t('billDetails.invoiceAmount.label2');
      };

      const includesWHT =
        form?.getFieldValue([
          NONPOFiledIds.BillDetails_BillDetails,
          formListName,
          NONPOFiledIds.BillDetails_IncludesWithHoldingTax,
        ]) === 'YES';

      return [
        createFormField({
          id: 'id',
          name: [formListName, NONPOFiledIds.BillDetails_BillId],
          type: 'text',
          isHidden: true,
          t,
        }),
        createFormField({
          id: 'invoiceDate',
          label: t('billDetails.invoiceDate.label'),
          name: [formListName, NONPOFiledIds.BillDetails_InvoiceDate],
          type: 'date',
          placeholder: t('billDetails.invoiceDate.placeHolder'),
          t,
          disabledDate: disableFutureDate,
        }),
        createFormField({
          id: 'invoiceNumber',
          label: t('billDetails.invoiceNumber.label'),
          name: [formListName, NONPOFiledIds.BillDetails_InvoiceNumber],
          type: 'input',
          placeholder: t('billDetails.invoiceNumber.placeHolder'),
          rules: INPUT_VALIDATION_MAX_CHARACTERS_PATTERN(
            true,
            50,
            t('requestForm.validation.rule.message.maxCharacters.50')
          ),
          t,
        }),
        createFormField({
          id: 'invoiceAmount',
          label: getlabelForInvoiceAmount(),
          name: [formListName, NONPOFiledIds.BillDetails_InvoiceAmount],
          type: 'inputNumber',
          placeholder: t('billDetails.invoiceAmount.placeHolder'),
          rules: [
            ...INPUT_VALIDATION_DECIMAL_Digit_PATTERN(
              true,
              3,
              t('requestForm.validation.rule.message.maxDecimal.3'),
              0
            ),
          ],
          t,
        }),
        createFormField({
          id: 'includesWithHoldingTax',
          label: t('billDetails.includesWithholdingTax.label'),
          name: [formListName, NONPOFiledIds.BillDetails_IncludesWithHoldingTax],
          type: 'select',
          initialValue: 'NO',
          options: getLookUps(NONPOFiledIds.BillDetails_IncludesWithHoldingTax),
          placeholder: t('billDetails.includesWithholdingTax.placeHolder'),
          isHidden: !isWthVisible,
          t,
        }),
        createFormField({
          id: 'withholdingTaxAmount',
          label: t('billDetails.withholdingTaxAmount.label'),
          name: [formListName, NONPOFiledIds.BillDetails_WithHoldingTaxAmount],
          type: 'input',
          disabled: true,
          isHidden: !isWthVisible,
          t,
        }),
        createFormField({
          id: 'AmountExcludingWithholdingTax',
          label: t('billDetails.amountExcludingWithholdingTax.label'),
          name: [formListName, NONPOFiledIds.BillDetails_AmountExcludingWithHoldingTax],
          type: 'input',
          disabled: true,
          isHidden: !includesWHT || !isWthVisible,
          t,
        }),
        createFormField({
          id: 'AmountIncludingWithholdingTax',
          label: t('billDetails.amountIncludingWithholdingTax.label'),
          name: [formListName, NONPOFiledIds.BillDetails_AmountIncludingWithHoldingTax],
          type: 'input',
          disabled: true,
          isHidden: includesWHT || !isWthVisible,
          t,
        }),
        createFormField({
          id: 'vatPercentage',
          label: t('billDetails.vatPercentage.label'),
          name: [formListName, NONPOFiledIds.BillDetails_VatPercentage],
          type: 'select',
          options: getLookUps(NONPOFiledIds.BillDetails_VatPercentage),
          placeholder: t('billDetails.vatPercentage.placeHolder'),
          isHidden: !isVatVisible,
          t,
        }),
        createFormField({
          id: 'vatAmount',
          label: t('billDetails.vatAmount.label'),
          name: [formListName, NONPOFiledIds.BillDetails_VatAmount],
          type: 'input',
          isHidden: !isVatVisible,
          disabled: true,
          t,
        }),
        createFormField({
          id: 'amountWithVat',
          label: t('billDetails.amountWithVat.label'),
          name: [formListName, NONPOFiledIds.BillDetails_AmountWithVat],
          type: 'input',
          disabled: true,
          isHidden: !isVatVisible,
          t,
        }),
        createFormField({
          id: 'conversionRate',
          label: t('billDetails.conversionRate.label'),
          name: [formListName, NONPOFiledIds.BillDetails_ConversionRate],
          type: 'input',
          disabled: true,
          rules: [
            {
              required: true,
              message:
                isConversationRateSuccess && !isConversationRateFailed && !isLoadingConversationRate
                  ? undefined
                  : t('messages.error.conversationRate'),
            },
          ],
          t,
        }),

        createFormField({
          id: 'invoiceAmountInSAR',
          label:
            (getPaymentOutsideSAValue && getServiceOutsideSAValue) ||
            (getPaymentOutsideSAValue && !getServiceOutsideSAValue)
              ? t('billDetails.invoiceAmountInSAR.label')
              : t('billDetails.invoiceAmountInSAR.label2'),
          name: [formListName, NONPOFiledIds.BillDetails_InvoiceAmountInSAR],
          type: 'input',
          disabled: true,
          t,
        }),

        createFormField({
          id: 'invoiceReason',
          label: t('billDetails.invoiceReason.label'),
          name: [formListName, NONPOFiledIds.BillDetails_InvoiceReason],
          type: 'textArea',
          placeholder: t('quotationDetails.description.placeHolder'),
          maxNumber: 400,
          t,
        }),

        createFormField(
          {
            id: 'attachment',
            label: t('quotationDetails.attachment.label'),
            name: [formListName, NONPOFiledIds.BillDetails_AttachmentList],
            type: 'file',
            valuePropName: 'fileList',
            onFileChange: (file) => {
              onAttachmentChange(formListName, file);
            },
            t,
          },
          true
        ),
      ];
    },
    [
      form,
      t,
      disableFutureDate,
      getLookUps,
      isWthVisible,
      isVatVisible,
      isConversationRateSuccess,
      isConversationRateFailed,
      isLoadingConversationRate,
      getPaymentOutsideSAValue,
      getServiceOutsideSAValue,
      onAttachmentChange,
    ]
  );

  return (
    <CustomFormGroupWrapper
      t={t}
      maxRecords={5}
      formGroupItems={BillDetailsFormGroupFields}
      formListName={NONPOFiledIds.BillDetails_BillDetails}
      sectionTitle={t('billDetails.section.title')}
      addButtonTitle={t('billDetails.add.button.title')}
      addButtonTitleSecondary={t('quotationDetails.addMore')}
      hasAtLeastOne={true}
      hasInitialValue={hasInitialValues}
      onRemoveFormListItem={updateDeletedQuotationIds}
      isEdit={isEdit}
      form={form}
    />
  );
};
