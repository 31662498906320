export const leavesDrawerConstants = {
  PROCESS_DEFINITION_KEY: 'Human_Resource.Employee_Services.Cancel_Leave_request',
  ANNUAL_LEAVE: 'Annual Leave',
  CANCEL_REQUEST_FORM_NAME: 'cancelRequestForm',
  RESCHEDULE_FORM_NAME: 'rescheduleForm',
  CANCEL_REASON_NAME: 'cancelReason',
  CANCEL_EMPLOYEE_FEEDBACK: 'employeefeedback',
  RESCHEDULE_START_DATE: 'newStartDate',
  RESCHEDULE_END_DATE: 'newEndDate',
  RESCHEDULE_REASON: 'rescheduleReason',
  ATTACHMENTS_NAME: 'attachments',
  MAX_TEXTAREA_LENGTH: 400,
};
