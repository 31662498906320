import {
  NonPoInvoiceAdvanceRequestResponse,
  NonPoInvoiceSubmitResponse,
  NonPoInvoiceSuppliersResponse,
  NONPOLookUpsResponseData,
  NonPoSubmitAdvance,
  NonPoSubmitExpense,
  NONPOConversionRateResponseData,
  NONPOWitholdingTaxRateResponseData,
  IGetNonPoConversionRateParams,
  IGetNonWitholdingTaxParams,
  IPayloadGetNonPoInvoiceAdvanceRequest,
  NonPoEditRequestResponse,
  NonPoUpdateRequestAdvanceParams,
  NonPoUpdateRequestExpenseParams,
  IBANValidationParams,
  IIBANValidation,
  IInvoiceNumberValidation,
  IInvoiceNumberValidationParams,
} from 'types';
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import {
  Core,
  NON_PO_INVOICE_ADVANCE_REQUESTS,
  NON_PO_INVOICE_LOOKUP_LIST,
  NON_PO_INVOICE_SUBMIT,
  NON_PO_INVOICE_SUPPLIER,
  NON_PO_INVOICE_CONVERTION_RATE,
  NON_PO_INVOICE_WITHHOLDING_TAX_RATE,
  NON_PO_INVOICE_EDIT_REQUEST,
  NON_PO_INVOICE_UPDATE_REQUEST,
  NON_PO_BUDGET_TEAM_GET_GEL_ACCOUNTS,
  NON_PO_BUDGET_TEAM_GET_COST_CENTER_ACCOUNTS,
  NON_PO_BUDGET_TEAM_GET_PROJECT_ACCOUNTS,
  NON_PO_BUDGET_TEAM_POST_TASK,
  NON_PO_BUDGET_TEAM_GET_REQUEST_TYPE,
  NON_PO_IBAN_VALIDATION,
  NON_PO_INVOICE_VALIDATION,
} from '../Core';

interface GetNonPoLookUpList {
  (): Promise<{ data: NONPOLookUpsResponseData }>;
}

interface PostNonPoInvoiceSubmitExpense {
  (data: NonPoSubmitExpense): AxiosPromise<NonPoInvoiceSubmitResponse>;
}

interface PostNonPoInvoiceSubmitAdvance {
  (data: NonPoSubmitAdvance): AxiosPromise<NonPoInvoiceSubmitResponse>;
}

interface GetNonPoInvoiceAdvanceRequests {
  (params: IPayloadGetNonPoInvoiceAdvanceRequest): Promise<{
    data: NonPoInvoiceAdvanceRequestResponse[];
  }>;
}

interface GetNonPoInvoiceSupplier {
  (codeOrName: string): Promise<{ data: NonPoInvoiceSuppliersResponse[] }>;
}

interface GetNonPoConversionRate {
  (params: IGetNonPoConversionRateParams): AxiosPromise<NONPOConversionRateResponseData>;
}

interface GetNonPoWitholdingTaxRate {
  (params: IGetNonWitholdingTaxParams): AxiosPromise<NONPOWitholdingTaxRateResponseData>;
}

interface GetNonPoInvoiceEditRequest {
  (processInstanceId: string): AxiosPromise<NonPoEditRequestResponse>;
}

interface PostNonPoInvoiceUpdateAdvanceRequest {
  (params: NonPoUpdateRequestAdvanceParams): AxiosPromise<NonPoInvoiceSubmitResponse>;
}

interface PostNonPoInvoiceUpdateExpenseRequest {
  (params: NonPoUpdateRequestExpenseParams): AxiosPromise<NonPoInvoiceSubmitResponse>;
}

interface GetNonPoBudgetLookup {
  (search: string): AxiosPromise<{ VALUE: string; DESCRIPTION: string }[]>;
}

interface GetNonPoBudgetRequestType {
  (requestId: string): AxiosPromise<{
    invoiceType: { value: string; key: string };
    distributionCombination: {
      GLAccount: { VALUE: string };
      costCenter: { VALUE: string };
      project: { VALUE: string };
    };
  }>;
}

interface PostNonPoBudgetTask {
  (
    processInstanceId: string,
    taskId: string,
    data: AxiosRequestConfig['data']
  ): AxiosPromise<unknown>;
}

interface PostIBANValidation {
  (params: IBANValidationParams): AxiosPromise<IIBANValidation>;
}

interface GETInvoiceNumberValidation {
  (params: IInvoiceNumberValidationParams): AxiosPromise<IInvoiceNumberValidation>;
}

class NONPoInvoice extends Core {
  getNonPoInvoiceLookUpList: GetNonPoLookUpList = async () =>
    this.get(NON_PO_INVOICE_LOOKUP_LIST());
  postNonPoInvoiceSubmitExpense: PostNonPoInvoiceSubmitExpense = async (data) =>
    this.post(NON_PO_INVOICE_SUBMIT(), data);
  postNonPoInvoiceSubmitAdvance: PostNonPoInvoiceSubmitAdvance = async (data) =>
    this.post(NON_PO_INVOICE_SUBMIT(), data);
  getNonPoInvoiceAdvanceRequests: GetNonPoInvoiceAdvanceRequests = async (params) =>
    this.get(NON_PO_INVOICE_ADVANCE_REQUESTS(params.supplierNumber, params?.processInstanceId));
  getNonPoInvoiceSuppliers: GetNonPoInvoiceSupplier = async (codeOrName) =>
    this.get(NON_PO_INVOICE_SUPPLIER(codeOrName));
  postNonPoConversionRate: GetNonPoConversionRate = async (params) =>
    this.post(NON_PO_INVOICE_CONVERTION_RATE(), params);

  postNonPoWitholdingTaxRate: GetNonPoWitholdingTaxRate = async (params) =>
    this.post(NON_PO_INVOICE_WITHHOLDING_TAX_RATE(), params);

  getNonPoInvoiceEditRequest: GetNonPoInvoiceEditRequest = async (processInstanceId) =>
    this.get(NON_PO_INVOICE_EDIT_REQUEST(processInstanceId));

  postNonPoInvoiceUpdateAdvanceRequest: PostNonPoInvoiceUpdateAdvanceRequest = async (params) =>
    this.put(
      NON_PO_INVOICE_UPDATE_REQUEST(params.taskId, params.processInstanceId),
      params.requestData
    );

  postNonPoInvoiceUpdateExpenseRequest: PostNonPoInvoiceUpdateExpenseRequest = async (params) =>
    this.put(
      NON_PO_INVOICE_UPDATE_REQUEST(params.taskId, params.processInstanceId),
      params.requestData
    );
  getNonPoBudgetGlAccounts: GetNonPoBudgetLookup = async (search) =>
    this.get(NON_PO_BUDGET_TEAM_GET_GEL_ACCOUNTS(), { params: { search } });
  getNonPoBudgetCostCenterAccounts: GetNonPoBudgetLookup = async (search) =>
    this.get(NON_PO_BUDGET_TEAM_GET_COST_CENTER_ACCOUNTS(), { params: { search } });
  getNonPoBudgetProjectAccounts: GetNonPoBudgetLookup = async (search) =>
    this.get(NON_PO_BUDGET_TEAM_GET_PROJECT_ACCOUNTS(), { params: { search } });
  getNonPoBudgetRequestType: GetNonPoBudgetRequestType = async (requestId) =>
    this.get(NON_PO_BUDGET_TEAM_GET_REQUEST_TYPE(requestId));
  postNonPoBudgetTask: PostNonPoBudgetTask = async (processInstanceId, taskId, data) =>
    this.post(NON_PO_BUDGET_TEAM_POST_TASK(processInstanceId, taskId), data);

  postIBANValidation: PostIBANValidation = async (data) =>
    this.post(NON_PO_IBAN_VALIDATION(), data);

  getInvoiceNumberValidation: GETInvoiceNumberValidation = async (data) =>
    this.get(NON_PO_INVOICE_VALIDATION(data.invoiceNumber));
}

export const nonPoInvoice = new NONPoInvoice();
