import { Core, CLAIM_TASK } from 'api';

interface PostClaimTask {
  (taskId: string, userId: string, rootPath?: string): Promise<unknown>;
}

class ClaimTask extends Core {
  postClaimTask: PostClaimTask = async (taskId, userId, rootPath) =>
    this.post(CLAIM_TASK(taskId, rootPath), { userId: userId });
}
export const claimTask = new ClaimTask();
