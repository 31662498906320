import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestCommonError, RequestStatus } from 'types';
import { ClaimTaskState } from './claimTaskType';

export const emptyClaimTaskState: ClaimTaskState = {
  status: RequestStatus.idle,
  error: null,
};

export type PostClaimTaskStart = PayloadAction<{
  taskId: string;
  userId: string;
  rootPath?: string;
}>;
export type PostClaimTaskSuccess = PayloadAction;
export type PostClaimTaskFail = PayloadAction<RequestCommonError>;
export type PostClaimTaskReset = PayloadAction;

const claimTask = createSlice({
  name: 'claimTask',
  initialState: emptyClaimTaskState,
  reducers: {
    postClaimTaskStart(state, _: PostClaimTaskStart) {
      state.status = RequestStatus.pending;
    },
    postClaimTaskSuccess(state, _: PostClaimTaskSuccess) {
      state.status = RequestStatus.resolved;
    },
    postClaimTaskFail(state, action: PostClaimTaskFail) {
      state.status = RequestStatus.rejected;
      state.error = action.payload;
    },
    postClaimTaskReset(state, _: PostClaimTaskReset) {
      state.status = RequestStatus.idle;
      state.error = null;
    },
  },
});
export const {
  postClaimTaskFail,
  postClaimTaskStart,
  postClaimTaskSuccess,
  postClaimTaskReset,
} = claimTask.actions;
export default claimTask.reducer;
