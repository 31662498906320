import { from, of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';

import * as API from 'api';
import { Epic } from '../types';
import { RootState } from '../reducers';
import {
  PostClaimTaskFail,
  PostClaimTaskStart,
  PostClaimTaskSuccess,
  postClaimTaskFail,
  postClaimTaskStart,
  postClaimTaskSuccess,
} from './claimTaskSlice';

export const claimTaskEpic: Epic<
  PostClaimTaskStart,
  PostClaimTaskSuccess | PostClaimTaskFail,
  RootState,
  typeof API
> = (action$, _, { claimTask }) =>
  action$.pipe(
    filter(postClaimTaskStart.match),
    exhaustMap(({ payload }) =>
      from(claimTask.postClaimTask(payload.taskId, payload.userId, payload.rootPath)).pipe(
        map(() => postClaimTaskSuccess()),
        catchError((error) => of(postClaimTaskFail(error?.response?.data)))
      )
    )
  );
