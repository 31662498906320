import React, { FC, useState, useCallback, useEffect } from 'react';
import { Popover, Row, Col, notification } from 'antd';
import { useTranslate } from 'translations';
import { RequestStatus } from 'types';
import {
  useDispatch,
  useSelector,
  postClaimTaskStart,
  ClaimTaskData,
  postClaimTaskReset,
  profileData,
} from 'store';
import { useHistory } from 'react-router-dom';
import { PagesUrls } from 'namespace';
import { useTasksContext } from 'routes/Tasks/TasksProvider';
import { ReassignUserData } from '../TaskSteps/TaskSteps.types';

import { SearchUser } from '../TaskSteps/ReassignTask/SearchUser';
import {
  StyledAssignTaskMessage,
  StyledAssignTaskTitle,
} from '../TaskSteps/ReassignTask/ReassignTask.styled';
import {
  StyledPopOver,
  StyledCheckIcon,
  StyledReassignTitle,
  StyledButton,
} from './ClaimTask.styled';

interface ClaimTaskProps {
  children: React.ReactElement;
  taskId?: string;
  groupId?: string;
  claimCallback?: () => void;
  rootPath?: string;
}

export const ClaimTask: FC<ClaimTaskProps> = ({
  children,
  taskId,
  groupId,
  claimCallback,
  rootPath,
}) => {
  const { t } = useTranslate();

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [reassignUser, setReassignUser] = useState<ReassignUserData>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const dispatch = useDispatch();
  const claimData = useSelector(ClaimTaskData);
  const { currentActiveTab } = useTasksContext();
  const [currentTaskId, setCurrentTaskId] = useState<string>();

  const { data: ProfileData } = useSelector(profileData);

  const history = useHistory();

  const onClaimTask = useCallback(
    (e) => {
      e.stopPropagation();
      if (reassignUser) {
        if (!reassignUser.id) {
          return;
        }

        if (reassignUser && taskId && reassignUser.id) {
          try {
            setIsLoading(true);
            dispatch(
              postClaimTaskStart({
                taskId: taskId,
                userId: reassignUser.id,
                rootPath,
              })
            );
          } catch {
            notification.error({
              message: t('messages.error.isClaimed'),
              description: t('messages.error.tryAgainLater'),
            });
            setIsLoading(false);
            return;
          }
        }
      }
    },
    [dispatch, reassignUser, rootPath, t, taskId]
  );

  const onCloseHandler = useCallback((e) => {
    e.stopPropagation();
    setIsVisible(false);
  }, []);

  const onClick = useCallback(
    (e) => {
      e.stopPropagation();
      setIsVisible(true);
      if (taskId) setCurrentTaskId(taskId);
    },
    [taskId]
  );

  useEffect(() => {
    setIsVisible(false);
  }, [currentActiveTab]);

  useEffect(() => {
    if (taskId && taskId !== currentTaskId) setIsVisible(false);
  }, [currentTaskId, taskId]);

  useEffect(() => {
    if (claimData.status === RequestStatus.resolved && reassignUser?.name) {
      setIsLoading(false);

      if (reassignUser.id === ProfileData?.adOid) {
        notification.success({
          message: <StyledAssignTaskTitle>{t('task.assignTask.title')}</StyledAssignTaskTitle>,
          description: (
            <StyledAssignTaskMessage>
              {t('task.assignTask.assignToHimSelf.success.message')}
            </StyledAssignTaskMessage>
          ),
        });
      } else {
        notification.success({
          message: <StyledAssignTaskTitle>{t('task.assignTask.title')}</StyledAssignTaskTitle>,
          description: (
            <StyledAssignTaskMessage>{`${t(
              'task.assignTask.assignTaskToOtherUser.success.message'
            )} ${reassignUser?.name}`}</StyledAssignTaskMessage>
          ),
        });
      }

      setReassignUser({ id: null, name: null });
      setIsVisible(false);

      if (claimCallback) {
        claimCallback();

        history.push(PagesUrls.MyWork);
      }
      dispatch(postClaimTaskReset());
    }
  }, [
    claimData.status,
    reassignUser?.name,
    t,
    claimCallback,
    history,
    dispatch,
    ProfileData?.adOid,
    reassignUser?.id,
  ]);

  const getCurrentSearchUserId = useCallback((assignUserId: string, assignUserName: string) => {
    if (assignUserId) {
      setReassignUser({ id: assignUserId, name: assignUserName });
    }
  }, []);

  const content = (
    <StyledPopOver>
      <Row align="middle" gutter={[10, 15]}>
        <Col>
          <StyledCheckIcon />
        </Col>
        <Col flex="auto">
          <StyledReassignTitle>{t('task.claimTask.title')}</StyledReassignTitle>
        </Col>
      </Row>
      <Row align="middle" gutter={[5, 5]}>
        <Col flex="auto">
          <StyledReassignTitle>{t('task.reassignTask.chooseUser')}</StyledReassignTitle>
        </Col>
      </Row>
      <Row align="middle" gutter={[5, 15]}>
        <Col flex="auto">
          <SearchUser
            currentSearchUserId={getCurrentSearchUserId}
            groupId={groupId}
            rootPath={rootPath}
          />
        </Col>
      </Row>
      <Row justify="end" gutter={[5, 5]}>
        <Col>
          <StyledButton onClick={onCloseHandler}>{t('no')}</StyledButton>
        </Col>
        <Col>
          <StyledButton type={'primary'} loading={isLoading} onClick={onClaimTask}>
            {t('yes')}
          </StyledButton>
        </Col>
      </Row>
    </StyledPopOver>
  );
  return (
    <div onClick={onClick}>
      <Popover placement="left" trigger={'click'} content={content} visible={isVisible}>
        <span>{children}</span>
      </Popover>
    </div>
  );
};
