import React, { FC, useCallback, useContext, useMemo } from 'react';
import { FormInstance } from 'antd/lib/form';
import { TType } from 'translations';
import { GroupOfFormItem, NONPOFiledIds, SelectOptions } from 'types';
import { createFormField, getSelectOptionObFromNonPoLookupItem } from 'utils/formCreation';
import {
  INPUT_VALIDATION_MAX_CHARACTERS_PATTERN,
  INPUT_VALIDATION_MAX_DIGIT_PATTERN_INPUT_STRING,
} from 'utils';
import { StaticFieldsGroup } from '../../../FieldsGroup';
import { NonPoRequestFormContext } from '../NonPoRequestForm';

type Props = {
  t: TType;
  form?: FormInstance;
};

export const PreferredPaymentMethodFormGroup: FC<Props> = ({ t, form }) => {
  const {
    dataLookUpResponse,
    isPaymentMethodCheck,
    isPaymentMethodIBAN,
    isPaymentMethodSADAD,
  } = useContext(NonPoRequestFormContext);

  const isPaymentMethodCheckMemo = useMemo(() => {
    return isPaymentMethodCheck;
  }, [isPaymentMethodCheck]);

  const getLookUps = useCallback(
    (fieldId: NONPOFiledIds) => {
      let selectOptionsOfNonPoLookUps: SelectOptions = [];
      if (dataLookUpResponse) {
        switch (fieldId) {
          case NONPOFiledIds.PaymentDetail_PaymentMethod:
            selectOptionsOfNonPoLookUps = getSelectOptionObFromNonPoLookupItem(
              dataLookUpResponse?.lookups?.PAYMENT_METHOD
            );
            break;
          default:
            break;
        }
      }
      return selectOptionsOfNonPoLookUps;
    },
    [dataLookUpResponse]
  );

  const PreferredPaymentMethodFormGroupFields = useCallback<() => GroupOfFormItem[]>(() => {
    return [
      {
        'Payment Method': [
          createFormField({
            id: 'paymentMethod',
            label: t('preferredPaymentMethod.paymentMethod.label'),
            name: NONPOFiledIds.PaymentDetail_PaymentMethod,
            type: 'select',
            options: getLookUps(NONPOFiledIds.PaymentDetail_PaymentMethod),
            placeholder: t('preferredPaymentMethod.paymentMethod.placeHolder'),
            t,
          }),
          createFormField({
            id: 'accountNumber',
            label: t('preferredPaymentMethod.accountNumber.label'),
            name: NONPOFiledIds.PaymentDetail_AccountNumber,
            type: 'input',
            placeholder: t('preferredPaymentMethod.accountNumber.placeHolder'),
            rules: INPUT_VALIDATION_MAX_CHARACTERS_PATTERN(
              true,
              24,
              t('requestForm.validation.rule.message.maxDigit.24.rule')
            ),
            isHidden: isPaymentMethodCheckMemo || isPaymentMethodSADAD || !isPaymentMethodIBAN,
            t,
            normalize: (value) => value.toUpperCase(),
          }),
          createFormField({
            id: 'sadaBillerCode',
            label: t('preferredPaymentMethod.sadaBillerCode.label'),
            name: NONPOFiledIds.PaymentDetail_SadaBillerCode,
            type: 'input',
            placeholder: t('preferredPaymentMethod.sadaBillerCode.placeHolder'),
            rules: INPUT_VALIDATION_MAX_DIGIT_PATTERN_INPUT_STRING(
              true,
              3,
              t('requestForm.validation.rule.message.numeric'),
              t('requestForm.validation.rule.message.maxDigit.3.rule')
            ),
            isHidden: isPaymentMethodCheckMemo || isPaymentMethodIBAN || !isPaymentMethodSADAD,
            t,
          }),
          createFormField({
            id: 'newAccountNumber',
            label: t('preferredPaymentMethod.newAccountNumber.label'),
            name: NONPOFiledIds.PaymentDetail_NewAccountNumber,
            type: 'input',
            placeholder: t('preferredPaymentMethod.newAccountNumber.placeHolder'),
            rules: INPUT_VALIDATION_MAX_CHARACTERS_PATTERN(
              isPaymentMethodSADAD,
              24,
              t('requestForm.validation.rule.message.maxDigit.24.rule')
            ),
            isHidden: isPaymentMethodCheckMemo || isPaymentMethodIBAN || !isPaymentMethodSADAD,
            t,
          }),
        ],
      },
    ];
  }, [t, getLookUps, isPaymentMethodIBAN, isPaymentMethodCheckMemo, isPaymentMethodSADAD]);
  return (
    <StaticFieldsGroup
      key={'form-group-payment-method'}
      t={t}
      getFormFields={PreferredPaymentMethodFormGroupFields}
      form={form}
      isShowSubTitle={false}
    ></StaticFieldsGroup>
  );
};
