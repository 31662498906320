import { useMutation } from 'react-query';
import { IInvoiceNumberValidationParams } from 'types';
import { AxiosError } from 'axios';
import { nonPoInvoice } from '../NONPoInvoice';

export const useInvoiceNumberValidation = () => {
  return useMutation<unknown, AxiosError, IInvoiceNumberValidationParams, unknown>(
    async (params: IInvoiceNumberValidationParams) => {
      const { data } = await nonPoInvoice.getInvoiceNumberValidation(params);
      return data;
    }
  );
};
