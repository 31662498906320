import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, Col, notification, Row } from 'antd';
import { HistoricActivityInstanceItem, LeaveStatus } from 'types';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import { useTranslate } from 'translations';

import { processDefinition as processDefinitionApi, task as taskApi } from 'api';
import { UploadFile } from 'antd/lib/upload/interface';
import {
  StyledSubmitButton as StyledSendForApprovalButton,
  convertToVariables,
  filterEmptyFields,
} from 'routes/Calendar';
import { StyledActionButtonWrapper } from '../LeaveDrawerDetail';
import { ModalPopover } from '../components/LeaveDrawerModalPopover';
import { leavesDrawerConstants } from '../constants';
import { RescheduleButtonStyled } from './ActionButtons.styled';

interface ActionButtonsProps {
  status: LeaveStatus;
  leaveId: number;
  setListViewType: () => void;
  dispatchGetLeaves: () => void;
  setCurrentLeaveId: (leaveId: string) => void;
  tasksLists?: HistoricActivityInstanceItem[];
  leaveType?: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
  duration: number | null;
  isHighFromBottom?: boolean;
}

const keyVariable = {
  submit: 'submit',
  cancel: 'cancel',
};

const disableAggregator = process.env.REACT_APP_DISABLE_AGGREGATOR === 'true';

export const ActionButtons: FC<ActionButtonsProps> = ({
  status,
  leaveId,
  setListViewType,
  setCurrentLeaveId,

  dispatchGetLeaves,
  tasksLists,
  leaveType,
  startDate,
  endDate,
  duration,
  isHighFromBottom,
}) => {
  const { t } = useTranslate();
  const [visible, setVisible] = React.useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = React.useState<boolean>(false);
  const [isScheduledAnnualLeave, setIsScheduledAnnualLeave] = React.useState<boolean>(false);
  const [form] = useForm();
  const [currentTaskId, setCurrentTaskId] = useState<string>('');

  const { ANNUAL_LEAVE, PROCESS_DEFINITION_KEY, ATTACHMENTS_NAME } = leavesDrawerConstants;

  const getTaskId = useCallback(async () => {
    if (tasksLists) {
      const task = tasksLists.find(({ activityId }) => activityId === 'Activity_saved');
      const taskId = task?.taskId;

      if (taskId) {
        setCurrentTaskId(taskId);
      }
    }
  }, [tasksLists]);

  useEffect(() => {
    if (status === LeaveStatus.planned) {
      getTaskId();
    }
  }, [status, getTaskId]);

  useEffect(() => {
    if (status === LeaveStatus.scheduled && leaveType === ANNUAL_LEAVE) {
      setIsScheduledAnnualLeave(true);
    }
  }, [status, leaveType, ANNUAL_LEAVE]);

  const handlePopOverRequest = useCallback(() => {
    setVisible(true);
  }, []);

  const onSendForApproval = useCallback(async () => {
    try {
      if (currentTaskId) {
        const formVariable = {
          [keyVariable.submit]: { value: true },
          [keyVariable.cancel]: { value: false },
        };
        if (disableAggregator) {
          await taskApi.postTask(currentTaskId, { variables: formVariable });
        } else {
          await taskApi.postTaskApproval(currentTaskId, { variables: formVariable });
        }

        setCurrentLeaveId('');
        dispatchGetLeaves();

        notification.success({
          message: `${t('leaves.drawer.sendForApproval.successTitle')}`,
          description: t('leaves.drawer.sendForApproval.successMessage'),
        });
      }
    } catch (e) {
      notification.error({
        message: t('messages.error.wentWrong'),
        description: t('messages.error.tryAgainLater'),
      });
    }
  }, [dispatchGetLeaves, setCurrentLeaveId, currentTaskId, t]);

  const onFinish = useCallback(async () => {
    const currentFormValues = form.getFieldsValue();
    const attachments = currentFormValues[ATTACHMENTS_NAME]?.fileList;
    delete currentFormValues[ATTACHMENTS_NAME];

    try {
      setConfirmLoading(true);

      if (status === LeaveStatus.scheduled) {
        const {
          data: postProcessDefinition,
        } = await processDefinitionApi.postProcessDefinitionForCancel(PROCESS_DEFINITION_KEY, {
          businessKey: `${leaveId}`,
        });
        const { data: tasksList } = await taskApi.getTaskList({
          processInstanceId: postProcessDefinition?.id,
        });
        const currentTaskId = tasksList[0]?.id;

        const attachmentPromises = attachments?.map(async (attachment: UploadFile) => {
          const formData = new FormData();
          formData.append('content', attachment.originFileObj as Blob);
          formData.append('attachment-name', attachment.name);
          formData.append('attachment-description', attachment.name);
          formData.append('attachment-type', attachment.type);
          formData.append('url', 'some_url');

          return await taskApi.postAttachment(currentTaskId, formData);
        });

        await Promise.all([
          disableAggregator
            ? taskApi.postTask(currentTaskId, {
                variables: convertToVariables(filterEmptyFields(currentFormValues)),
              })
            : taskApi.postTaskApproval(currentTaskId, {
                variables: convertToVariables(filterEmptyFields(currentFormValues)),
              }),
          attachments ? Promise.all(attachmentPromises) : undefined,
        ]);
      } else {
        const cancelFormVariable = {
          [keyVariable.submit]: { value: false },
          [keyVariable.cancel]: { value: true },
        };

        if (currentFormValues['employeefeedback']) {
          cancelFormVariable['employeefeedback'] = { value: currentFormValues['employeefeedback'] };
        }

        if (currentTaskId) {
          if (disableAggregator) {
            await taskApi.postTask(currentTaskId, { variables: cancelFormVariable });
          } else {
            await taskApi.postTaskApproval(currentTaskId, { variables: cancelFormVariable });
          }
        }
      }

      notification.success({
        message: `${t('leaves.cancel.notify.requestCanceled')}`,
        description:
          status === LeaveStatus.planned
            ? t('leaves.cancel.notify.statusChanged')
            : t('leaves.cancel.notify.cancelationInProgress'),
      });
      setListViewType();
      setCurrentLeaveId('');
      dispatchGetLeaves();
      setVisible(false);
      setCurrentTaskId('');
    } catch (e) {
      notification.error({
        message: t('messages.error.wentWrong'),
        description: t('messages.error.tryAgainLater'),
      });
    } finally {
      setConfirmLoading(false);
    }
  }, [
    dispatchGetLeaves,
    form,
    leaveId,
    setCurrentLeaveId,
    setListViewType,
    status,
    t,
    currentTaskId,
    ATTACHMENTS_NAME,
    PROCESS_DEFINITION_KEY,
  ]);

  const handleModalOk = useCallback(() => {
    status === LeaveStatus.planned ? onFinish() : form.submit();
  }, [form, onFinish, status]);

  const handleCancelButton = useCallback(() => {
    setVisible(false);
    setConfirmLoading(false);
  }, []);

  return (
    <>
      {status === LeaveStatus.planned || status === LeaveStatus.scheduled ? (
        <StyledActionButtonWrapper
          isScheduledAnnualLeave={status === LeaveStatus.scheduled && leaveType === ANNUAL_LEAVE}
          isHighFromBottom={isHighFromBottom}
        >
          <Row gutter={[16, 16]} justify="space-between">
            {status === LeaveStatus.planned ? (
              <Col>
                <StyledSendForApprovalButton onClick={onSendForApproval}>
                  {t('leaves.drawer.sendForApproval')}
                  <ArrowRightOutlined />
                </StyledSendForApprovalButton>
              </Col>
            ) : null}
            <Col flex="auto" />

            <Col>
              {status === LeaveStatus.scheduled && leaveType === ANNUAL_LEAVE ? (
                <RescheduleButtonStyled onClick={handlePopOverRequest}>
                  {t('leaves.drawer.reschedule')}
                </RescheduleButtonStyled>
              ) : (
                <Button danger type="text" onClick={handlePopOverRequest}>
                  {t('leaves.cancel.cancelRequest')}
                </Button>
              )}
            </Col>
          </Row>
          <ModalPopover
            visible={visible}
            handleModalOk={handleModalOk}
            handleCancelButton={handleCancelButton}
            confirmLoading={confirmLoading}
            isScheduledAnnualLeave={isScheduledAnnualLeave}
            isScheduledLeave={status === LeaveStatus.scheduled}
            onFinish={onFinish}
            form={form}
            startDate={startDate}
            endDate={endDate}
            duration={duration}
            t={t}
            leaveId={leaveId}
            setConfirmLoading={setConfirmLoading}
          />
        </StyledActionButtonWrapper>
      ) : null}
    </>
  );
};
