import { useMutation } from 'react-query';
import { IBANValidationParams } from 'types';
import { AxiosError } from 'axios';
import { notification } from 'antd';
import { useTranslate } from 'translations';
import { nonPoInvoice } from '../NONPoInvoice';

export const useIBANValidation = () => {
  const { t } = useTranslate();
  return useMutation(
    async (params: IBANValidationParams) => {
      const { data } = await nonPoInvoice.postIBANValidation(params);
      return data;
    },
    {
      onError: (error: AxiosError) => {
        notification.error({
          message: error?.response?.data?.errorMessage ?? t('messages.error.wentWrong'),
        });
      },
    }
  );
};
