import React, { FC, useCallback, useContext, useMemo } from 'react';
import { FormInstance } from 'antd/lib/form';
import { TType } from 'translations';
import moment from 'moment';
import { GroupOfFormItem, NONPOFiledIds, SelectOptions } from 'types';
import {
  FORM_ITEM_50_CHARACTER_RULES,
  FORM_ITEM_REQUIRED_RULE,
  INPUT_VALIDATION_DECIMAL_Digit_PATTERN,
  INPUT_VALIDATION_MAX_CHARACTERS_PATTERN,
} from 'utils';
import {
  createFormField,
  getSelectOptionObFromNonPoLookupItem,
  getSelectOptionObFromNonPoLookupWHTRequestDetail,
} from 'utils/formCreation';
import { selectFilterOption } from 'utils/getFilterOptions';
import { StaticFieldsGroup } from '../../../FieldsGroup';
import {
  MISCELLANEOUS_SUPPLIER_ID,
  NonPoRequestFormContext,
  TRAINING_SUPPLIER_ID,
} from '../NonPoRequestForm';
import { InvoiceTypeDisclaimer, InvoiceTypeDisclaimerWrapper } from './CustomFormGroup.styled';

type Props = {
  t: TType;
  form?: FormInstance;
};

export const RequestDetailsFormGroup: FC<Props> = ({ t, form }) => {
  const {
    utilityTypeValue,
    isUtility,
    isEvent,
    isExpense,
    isEdit,
    dataLookUpResponse,
    supplierOptions,
    searchSupplier,
    isNonPoInvoiceSuppliersLoading,
    isServiceTypeMandatory,
    erpInvoiceOptions,
    isNonPoERPInvoiceLoading,
    erpInvoiceSearchString,
    showERPInvoice,
    isAnyQuotationDetailMarkAsRecommended,
    requestDetailSupplierLabel,
    paymentOutsideSAValue,
    serviceOutsideSAValue,
    isAdvanceAfterSelection,
    formValidationErrors,
  } = useContext(NonPoRequestFormContext);

  const getLookUps = useCallback(
    (fieldId: NONPOFiledIds) => {
      let selectOptionsOfNonPoLookUps: SelectOptions = [];
      if (dataLookUpResponse) {
        switch (fieldId) {
          case NONPOFiledIds.RequestDetail_ExpenseType:
            selectOptionsOfNonPoLookUps = getSelectOptionObFromNonPoLookupItem(
              dataLookUpResponse?.lookups?.EXPENSE_TYPE
            );
            break;
          case NONPOFiledIds.RequestDetail_InvoiceType:
            selectOptionsOfNonPoLookUps = getSelectOptionObFromNonPoLookupItem(
              dataLookUpResponse?.lookups?.INVOICE_TYPE
            );
            break;
          case NONPOFiledIds.RequestDetail_UtilityType:
            selectOptionsOfNonPoLookUps = getSelectOptionObFromNonPoLookupItem(
              dataLookUpResponse?.lookups?.UTILITY_TYPE
            );
            break;
          case NONPOFiledIds.RequestDetail_Currency:
            selectOptionsOfNonPoLookUps = dataLookUpResponse.currencyList;
            break;
          case NONPOFiledIds.RequestDetail_AlreadyPaid:
            selectOptionsOfNonPoLookUps = getSelectOptionObFromNonPoLookupItem(
              dataLookUpResponse?.lookups?.ALREADY_PAID
            );
            break;
          case NONPOFiledIds.RequestDetail_PaymentToBeDoneOutsideSaudiArabia:
            selectOptionsOfNonPoLookUps = getSelectOptionObFromNonPoLookupItem(
              dataLookUpResponse?.lookups?.PAYMENT_OUTSIDE_SAUDI_ARABIA
            );
            break;
          case NONPOFiledIds.RequestDetail_ServiceProvidedOutsideSaudiArabia:
            selectOptionsOfNonPoLookUps = getSelectOptionObFromNonPoLookupItem(
              dataLookUpResponse?.lookups?.SERVICE_OUTSIDE_SAUDI_ARABIA
            );
            break;
          case NONPOFiledIds.RequestDetail_ServiceType:
            selectOptionsOfNonPoLookUps = getSelectOptionObFromNonPoLookupItem(
              dataLookUpResponse?.lookups?.SERVICE_TYPE
            );
            break;
          case NONPOFiledIds.RequestDetail_WithHoldingTaxPercentage:
            selectOptionsOfNonPoLookUps = getSelectOptionObFromNonPoLookupWHTRequestDetail(
              dataLookUpResponse?.lookups?.WHT_PERCENTAGE
            );
            break;
          default:
            break;
        }
      }
      return selectOptionsOfNonPoLookUps;
    },
    [dataLookUpResponse]
  );

  const showOtherUtilityOption = useMemo(() => {
    if (!(isUtility && utilityTypeValue === 'OTHERS')) {
      form?.setFieldsValue({ [`${NONPOFiledIds.RequestDetail_OtherUtilityType}`]: undefined });
      return false;
    }
    return true;
  }, [form, isUtility, utilityTypeValue]);

  const mSupplierOptions = useMemo(() => {
    return supplierOptions;
  }, [supplierOptions]);

  const mERPInvoiceOptions = useMemo(() => {
    return erpInvoiceOptions;
  }, [erpInvoiceOptions]);

  const isTotalAmountRequired = useMemo(() => {
    return isExpense ? true : isAnyQuotationDetailMarkAsRecommended;
  }, [isAnyQuotationDetailMarkAsRecommended, isExpense]);

  const showMiscellanouesSupplierName = useMemo(() => {
    const show = isExpense && requestDetailSupplierLabel?.includes(MISCELLANEOUS_SUPPLIER_ID);
    if (!show) {
      form?.setFieldsValue({
        [`${NONPOFiledIds.RequestDetail_Miscellaneous_SupplierName}`]: undefined,
      });
    }
    return show;
  }, [form, isExpense, requestDetailSupplierLabel]);

  const showTrainingSupplierName = useMemo(() => {
    const show = isExpense && requestDetailSupplierLabel === TRAINING_SUPPLIER_ID;
    if (!show) {
      form?.setFieldsValue({
        [`${NONPOFiledIds.RequestDetail_Training_SupplierName}`]: undefined,
      });
    }
    return show;
  }, [form, isExpense, requestDetailSupplierLabel]);

  const showWithHoldingTaxPercentage = useMemo(() => {
    const show = paymentOutsideSAValue === 'YES' && serviceOutsideSAValue === 'NO';
    if (!show) {
      form?.setFieldsValue({
        [`${NONPOFiledIds.RequestDetail_WithHoldingTaxPercentage}`]: undefined,
      });
    }
    return show;
  }, [form, paymentOutsideSAValue, serviceOutsideSAValue]);

  const RequestDetailsFormGroupFields = useCallback<() => GroupOfFormItem[]>(() => {
    const isErrorInField = (fieldKey: NONPOFiledIds) => {
      return (
        (formValidationErrors.find((fError) => fError.name?.toString() === fieldKey)?.errors ?? [])
          .length > 0
      );
    };
    return [
      {
        'Request Details': [
          createFormField({
            id: 'startDate',
            label: t('requestDetails.expenseType.label'),
            name: NONPOFiledIds.RequestDetail_ExpenseType,
            type: 'select',
            disabled: isEdit,
            options: getLookUps(NONPOFiledIds.RequestDetail_ExpenseType),
            placeholder: t('requestDetails.expenseType.placeHolder'),
            t,
          }),
          createFormField({
            id: 'invoiceType',
            label: t('requestDetails.invoiceType.label'),
            name: NONPOFiledIds.RequestDetail_InvoiceType,
            type: 'select',
            disabled: isEdit,
            options: getLookUps(NONPOFiledIds.RequestDetail_InvoiceType),
            placeholder: t('requestDetails.invoiceType.placeHolder'),
            t,
            disclaimer: isAdvanceAfterSelection && (
              <InvoiceTypeDisclaimerWrapper>
                <InvoiceTypeDisclaimer>
                  {t('requestDetails.invoiceType.disclaimer')}
                </InvoiceTypeDisclaimer>
              </InvoiceTypeDisclaimerWrapper>
            ),
          }),
          createFormField({
            id: 'utility',
            label: t('requestDetails.utility.label'),
            name: NONPOFiledIds.RequestDetail_UtilityType,
            type: 'select',
            options: getLookUps(NONPOFiledIds.RequestDetail_UtilityType),
            placeholder: t('requestDetails.utility.placeHolder'),
            t,
            isHidden: !isUtility,
          }),
          createFormField({
            id: 'utility.others',
            label: t('requestDetails.utility.others.label'),
            name: NONPOFiledIds.RequestDetail_OtherUtilityType,
            type: 'input',
            placeholder: t('requestDetails.utility.others.placeHolder'),
            t,
            isHidden: !showOtherUtilityOption,
            rules: [...FORM_ITEM_REQUIRED_RULE, ...FORM_ITEM_50_CHARACTER_RULES],
          }),
          createFormField({
            id: 'department',
            label: t('requestDetails.department.label'),
            name: NONPOFiledIds.RequestDetail_Department,
            type: 'input',
            disabled: true,
            t,
          }),
          createFormField({
            id: 'supplierName',
            label: t('requestDetails.supplierName.label'),
            name: NONPOFiledIds.RequestDetail_SupplierName,
            type: 'select',
            hasFeedback: true,
            filterOption: false,
            isLoading: isNonPoInvoiceSuppliersLoading,
            options: mSupplierOptions,
            onSearch: searchSupplier,
            noResultFound: 'No such supplier exists',
            placeholder: t('requestDetails.supplierName.placeHolder'),
            t,
            isHidden: !isExpense,
            disabled: isEdit,
            selectTheValueAsKey: true,
            selectWidth: 309,
          }),
          createFormField({
            id: NONPOFiledIds.RequestDetail_Miscellaneous_SupplierName,
            label: t('requestDetails.miscellaneous_supplierName.label'),
            name: NONPOFiledIds.RequestDetail_Miscellaneous_SupplierName,
            type: 'input',
            t,
            isHidden: !showMiscellanouesSupplierName,
            disabled: isEdit,
            withFullRow: true,
            containerCol:
              isUtility && !showOtherUtilityOption
                ? undefined
                : {
                    xs: { span: 24 },
                    sm: { span: 24 },
                    md: { span: 12, offset: 12 },
                    xxl: { span: 12, offset: 12 },
                  },
            rules: INPUT_VALIDATION_MAX_CHARACTERS_PATTERN(
              true,
              128,
              t('requestForm.validation.rule.message.maxCharacters.128')
            ),
          }),
          createFormField({
            id: NONPOFiledIds.RequestDetail_Training_SupplierName,
            label: t('requestDetails.training_supplierName.label'),
            name: NONPOFiledIds.RequestDetail_Training_SupplierName,
            type: 'input',
            t,
            isHidden: !showTrainingSupplierName,
            disabled: isEdit,
            withFullRow: true,
            containerCol:
              isUtility && !showOtherUtilityOption
                ? undefined
                : {
                    xs: { span: 24 },
                    sm: { span: 24 },
                    md: { span: 12, offset: 12 },
                    xxl: { span: 12, offset: 12 },
                  },
            rules: INPUT_VALIDATION_MAX_CHARACTERS_PATTERN(
              true,
              128,
              t('requestForm.validation.rule.message.maxCharacters.128')
            ),
          }),
          createFormField(
            {
              id: 'erpInvoice',
              label: t('requestDetails.erpInvoice.label'),
              name: NONPOFiledIds.RequestDetail_ERPInvoice,
              type: 'multiSelect',
              hasFeedback: true,
              filterOption: (input, option) => {
                return (option?.label ?? option?.children ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              },
              rules: [],
              isLoading: isNonPoERPInvoiceLoading,
              options: mERPInvoiceOptions,
              searchText: erpInvoiceSearchString,
              placeholder: t('requestDetails.erpInvoice.placeHolder'),
              t,
              isHidden: !(isExpense && showERPInvoice),
              withFullRow: isUtility && !showOtherUtilityOption,
              containerCol:
                isUtility && !showOtherUtilityOption
                  ? undefined
                  : {
                      xs: { span: 24 },
                      sm: { span: 24 },
                      md: { span: 12, offset: 12 },
                      xxl: { span: 12, offset: 12 },
                    },
            },
            true
          ),
          createFormField({
            id: NONPOFiledIds.RequestDetail_ERPInvoiceReferenceNumber,
            label: t('requestDetails.erpInvoiceReferenceNumber.label'),
            name: NONPOFiledIds.RequestDetail_ERPInvoiceReferenceNumber,
            type: 'input',
            rules: INPUT_VALIDATION_MAX_CHARACTERS_PATTERN(
              true,
              50,
              t('requestForm.validation.rule.message.maxCharacters.50')
            ),
            placeholder: t('requestDetails.erpInvoiceReferenceNumber.placeHolder'),
            t,
            disclaimer: (
              <InvoiceTypeDisclaimerWrapper
                isError={isErrorInField(NONPOFiledIds.RequestDetail_ERPInvoiceReferenceNumber)}
              >
                <InvoiceTypeDisclaimer>
                  {t('requestDetails.erpInvoiceReferenceNumber.disclaimer')}
                </InvoiceTypeDisclaimer>
              </InvoiceTypeDisclaimerWrapper>
            ),
          }),
          createFormField({
            id: 'currency',
            label: t('requestDetails.currency.label'),
            name: NONPOFiledIds.RequestDetail_Currency,
            type: 'select',
            options: getLookUps(NONPOFiledIds.RequestDetail_Currency),
            filterOption: selectFilterOption,
            placeholder: t('requestDetails.currency.placeHolder'),
            t,
            isHidden: !isExpense,
          }),
          createFormField({
            id: 'totalAmount',
            label: t('requestDetails.totalAmount.label'),
            name: NONPOFiledIds.RequestDetail_TotalAmount,
            type: 'input',
            placeholder: t('requestDetails.totalAmount.placeHolder'),
            rules: [
              ...INPUT_VALIDATION_DECIMAL_Digit_PATTERN(
                isTotalAmountRequired,
                3,
                t('requestForm.validation.rule.message.maxDecimal.3')
              ),
            ],
            t,
            disabled: true,
          }),
          createFormField({
            id: 'alreadyPaid',
            label: t('requestDetails.alreadyPaid.label'),
            name: NONPOFiledIds.RequestDetail_AlreadyPaid,
            type: 'select',
            options: getLookUps(NONPOFiledIds.RequestDetail_AlreadyPaid),
            placeholder: t('requestDetails.alreadyPaid.placeHolder'),
            t,
          }),
          createFormField({
            id: 'paymentToBeDoneOutsideSaudiArabia',
            label: t('requestDetails.paymentToBeDoneOutsideSaudiArabia.label'),
            name: NONPOFiledIds.RequestDetail_PaymentToBeDoneOutsideSaudiArabia,
            type: 'select',
            disabled: isEdit,
            options: getLookUps(NONPOFiledIds.RequestDetail_PaymentToBeDoneOutsideSaudiArabia),
            placeholder: t('requestDetails.paymentToBeDoneOutsideSaudiArabia.placeHolder'),
            t,
          }),
          createFormField({
            id: 'serviceProvidedOutsideSaudiArabia',
            label: t('requestDetails.serviceProvidedOutsideSaudiArabia.label'),
            name: NONPOFiledIds.RequestDetail_ServiceProvidedOutsideSaudiArabia,
            type: 'select',
            disabled: isEdit,
            options: getLookUps(NONPOFiledIds.RequestDetail_ServiceProvidedOutsideSaudiArabia),
            placeholder: t('requestDetails.serviceProvidedOutsideSaudiArabia.placeHolder'),
            t,
          }),
          createFormField({
            id: NONPOFiledIds.RequestDetail_WithHoldingTaxPercentage,
            label: t('requestDetails.withHoldingTaxPercentage.label'),
            name: NONPOFiledIds.RequestDetail_WithHoldingTaxPercentage,
            type: 'select',
            options: getLookUps(NONPOFiledIds.RequestDetail_WithHoldingTaxPercentage),
            placeholder: t('requestDetails.withHoldingTaxPercentage.placeholder'),
            t,
            hasIconInfo: true,
            iconInfoLabel: t('requestDetails.withHoldingTaxPercentage.info'),
            isHidden: !showWithHoldingTaxPercentage,
          }),
          createFormField({
            id: 'serviceType',
            label: t('requestDetails.serviceType.label'),
            name: NONPOFiledIds.RequestDetail_ServiceType,
            type: 'select',
            rules: FORM_ITEM_REQUIRED_RULE,
            options: getLookUps(NONPOFiledIds.RequestDetail_ServiceType),
            placeholder: t('requestDetails.serviceType.placeHolder'),
            isHidden: !isServiceTypeMandatory,
            t,
          }),
          createFormField({
            id: 'eventDate',
            label: t('quotationDetails.eventDate.label'),
            name: NONPOFiledIds.RequestDetail_EventDate,
            type: 'date',
            rules: [
              {
                validator: (rule, value) => {
                  const selectedDate = moment(value);
                  const twoMonthsFromNow = moment().add(2, 'months');
                  if (selectedDate.isAfter(twoMonthsFromNow) && isExpense) {
                    return Promise.reject(t('nonPo.requestDetail.advanceRequest.error.message'));
                  }
                  return Promise.resolve();
                },
              },
            ],
            isHidden: !isEvent,
            t,
          }),
          createFormField({
            id: 'description',
            label: t('quotationDetails.description.label'),
            name: NONPOFiledIds.RequestDetail_Description,
            type: 'textArea',
            placeholder: t('quotationDetails.description.placeHolder'),
            maxNumber: 200,
            t,
          }),
        ],
      },
    ];
  }, [
    t,
    isEdit,
    getLookUps,
    isAdvanceAfterSelection,
    isUtility,
    showOtherUtilityOption,
    isNonPoInvoiceSuppliersLoading,
    mSupplierOptions,
    searchSupplier,
    isExpense,
    showMiscellanouesSupplierName,
    showTrainingSupplierName,
    isNonPoERPInvoiceLoading,
    mERPInvoiceOptions,
    erpInvoiceSearchString,
    showERPInvoice,
    formValidationErrors,
    isTotalAmountRequired,
    showWithHoldingTaxPercentage,
    isServiceTypeMandatory,
    isEvent,
  ]);

  return (
    <StaticFieldsGroup
      key={'form-group-request-details'}
      t={t}
      getFormFields={RequestDetailsFormGroupFields}
      form={form}
      isShowSubTitle={false}
    ></StaticFieldsGroup>
  );
};
